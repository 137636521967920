<template>
    <div class="container-lg">
        <div class="row residentForm-heading-button-row">
            <app-button :isSelected="activeTab === 1" @click="viewTableState(1)"
            >Current Change in Condition
            </app-button
            >
            <app-button :isSelected="activeTab !== 1" @click="addToTableState(2)"
            >{{ this.isInEditMode ? "Edit" : "Add" }} Change in Condition
                <font-awesome-icon :icon="['fas', 'plus']"
                />
            </app-button>
        </div>

        <template v-if="isInAddMode === true">
            <div class="d-flex justify-content-center">
                <p
                    class="
            resident-signSymptoms-step-text
            resident-signSymptoms-step-text-spacing
          "
                >
                    STEP 1: SELECT
                </p>
                <p
                    :class="{
            'resident-signSymptoms-step-text resident-signSymptoms-step-text-spacing':
              meSignSymptomStep >= 2,
            'resident-signSymptoms-step-text-outline resident-signSymptoms-step-text-spacing':
              meSignSymptomStep < 2,
          }"
                >
                    STEP 2: SITUATION
                </p>
                <p
                    :class="{
            'resident-signSymptoms-step-text resident-signSymptoms-step-text-spacing':
              meSignSymptomStep >= 3,
            'resident-signSymptoms-step-text-outline resident-signSymptoms-step-text-spacing':
              meSignSymptomStep < 3,
          }"
                >
                    STEP 3: ASSESS
                </p>
            </div>

            <div class="align-content-start flex-wrap">
                <form action="">
                    <b-form-group
                        id="form-group-sign-and-symptoms"
                        label-for="sign-and-symptom"
                        label-cols-md="3"
                        v-if="meSignSymptomStep < 10"
                    >
                        <template slot="label"><b>Select a Change in Condition</b></template>
                        <b-form-select
                            id="sign-and-symptom"
                            v-model="selectSignAndSymptom"
                            :options="getSignsAndSymptoms"
                        ></b-form-select>
                    </b-form-group>

                    <div class="row" v-if="showSituationWindow">
                        <situation></situation>
                    </div>

                    <div class="row" v-if="showAssessWindow">
                        <assess></assess>
                    </div>

                    <div class="d-flex justify-content-between">
                        <app-button @click="goBack"
                        ><strong><i class="fas fa-angle-left"/> BACK</strong></app-button
                        >

                        <app-button
                            v-if="meSignSymptomStep === 1"
                            :disabled="selectSignAndSymptom == null"
                            @click="proceedToSituation"
                        ><strong>ADD <i class="fas fa-angle-right"/></strong
                        ></app-button>

                        <app-button
                            v-if="meSignSymptomStep === 2"
                            class="float-right"
                            @click="proceedToAssess"
                            :disabled="canProceedToAssess"
                        ><strong>NEXT <i class="fas fa-angle-right"/></strong
                        ></app-button>

                        <div>
                        <app-button
                            v-if="meSignSymptomStep === 3"
                            class="float-right"
                            @click="addAssessment"
                            :is-danger="assessment.immediacy === 'true'"
                        >
                            <strong>Add</strong>
                        </app-button>
                        </div>
                    </div>
                </form>
            </div>
        </template>
        <template v-else>
            <episode-signs-and-symptoms-table
                :action="editPatientSymptom"
            ></episode-signs-and-symptoms-table>
        </template>

        <b-modal
            ref="modal-unsaved-changes"
            title="Unsaved Changes"
            centered
            header-bg-variant="dark"
            header-text-variant="danger"
            hide-footer
            hide-header-close
        >
            <p class="my-4">
                You have unsaved changes. <br />
                You must save your changes before you can communicate them to the
                primary care provider <br />
                Would you like to save your changes?
            </p>
            <div class="text-center">
                <app-button @click="saveResidentData()" :is-disabled="isSaving">{{ savingButtonText}}</app-button>
                <app-button
                    @click="cancelMessageBoxShow('modal-unsaved-changes')"
                    isDanger
                    :is-disabled="isSaving"
                >CLOSE</app-button
                >
            </div>
        </b-modal>

        <b-modal
            ref="modal-error-saving"
            title="Error Saving Changes"
            centered
            header-bg-variant="dark"
            header-text-variant="danger"
            hide-footer
            hide-header-close
        >
            <p class="my-4">
                <strong>We were unable to save the changes.</strong>
            </p>

            <h3>
                {{ episodeErrorsText }}
            </h3>
            <div v-for="error in episodeErrors.errors" :key="(index, error)">
                {{ error }}
            </div>

            <div class="text-center">
                <app-button @click="cancelMessageBoxShow('modal-error-saving')" isDanger
                >CLOSE</app-button
                >
            </div>
        </b-modal>
    </div>
</template>

<script>
import {mapGetters, mapActions} from "vuex";
import AppButton from "@/components/AppButton";
import EpisodeSignsAndSymptomsTable from "@/components/Episode/EpisodeSignsAndSymptomsTable";
import Situation from "@/components/Episode/SignsAndSymptoms/Situation"
import Assess from "@/components/Episode/SignsAndSymptoms/Assess"
import {generateId} from "../../helpers/helpers";
import ChangeInConditionPageState from "../../enums/modules/ChangeInConditionPageState";

export default {
    name: "ResidentDataSignAndSymptoms",
    components: {
        AppButton,
        EpisodeSignsAndSymptomsTable,
        Situation,
        Assess,
    },

    computed: {
        ...mapGetters({
            signsAndSymptoms: "configuration/signsAndSymptoms",
            interventions: "configuration/interventions",
            getSignsAndSymptoms: "configuration/getSignsAndSymptoms",
            episode: "episode/getEpisode",
            user: "authentication/user",
            getEpisodeSignsAndSymptoms: "episode/getSignsAndSymptoms",
            getCurrentSignsAndSymptom: "episode/getCurrentSignsAndSymptom",
            assessment: "episode/getAssessment",
            getEpisodeId: "episode/getEpisodeId",
            isDirty: "episode/isDirty",
            episodeErrors: "episode/episodeErrors",
            episodeHasErrors: "episode/episodeHasErrors",
        }),

        savingButtonText() {
            if(this.isSaving) {
                return "Saving..."
            }

            return "Save"
        },

        selectSignAndSymptom: {
            get() {

                return this.getCurrentSignsAndSymptom
            },
            set(value) {

                this.setNewSignAndSymptom(value)
            },
        },
        episodeErrorsText() {
            if (this.episodeHasErrors) {
                return this.episodeErrors.error;
            }

            return "";
        },
        selectedSignAndSymptomObject() {

            return this.signsAndSymptoms.find(symptom => symptom.id === this.getCurrentSignsAndSymptom)
        },

      canProceedToAssess() {
          return this.assessment?.started_at === null
      },

        hasIntervention() {

            return this.selectedSignAndSymptomObject.linked_intervention !== ''
        },
    },

    data() {
        return {
            items: [],
            meNotAuthorised: false,
            isEditShow: false,
            meSymptomList: [{id: 1, symptomName: "Fall"}],
            meSelectedSymptom: -1,
            activeTab: 1,
            meSignSymptomStep: 1,
            isInAddMode: false,
            meAddSymptom: "",
            patient_symptoms: {
                episode_id: "",
                symptom_token: "",
                type: "",
                started: "",
                occurrence: "",
                severity: "",
                last_treatment: "",
                negative_factors: "",
                positive_factors: "",
                other: "",
                intervention: "",
                started_at: "",
            },
            episodeSignsAndSymptom: {
                episode_id: null,
                signs_and_symptom_id: null,
                is_immediate: false,
                started_at: null,
                is_reoccurrence: false,
                severity_direction: 'same',
                last_treatment: null,
                positive_factors: null,
                negative_factors: null,
                other_factors: null,
                requires_notification: false,
                has_notified: false,
                status: true,
                action: 'R'
            },
            symptom_immediate_text: "",
            symptom_non_immediate_text: "",
            editedPatientSymptom: "",
            isInEditMode: false,
            isLoading: false,

            showSituationWindow: false,
            showAssessWindow: false,
            selectedSignAndSymptom: null,
            isSaving: false,
        };
    },

    mounted() {
        this.loadPatientSymptoms();
    },

    methods: {
        ...mapActions({
            setSymptom: "episode/setSignAndSymptom",
            setNewSignAndSymptom: "episode/setNewSignAndSymptom",
            clearAssessment: "episode/clearAssessment",
            addSignsAndSymptom: "episode/addOrUpdateSignsAndSymptom",
            setAssessment: "episode/setAssessment",
            saveEpisode: "episode/saveEpisode",
            openMessages: "messages/openMessages",
            addOrUpdateIntervention: "episode/addOrUpdateIntervention",
            setInterventionName: "episode/setInterventionName",
            setEpisodeInterventionId: "episode/setEpisodeInterventionId",
        }),

        editPatientSymptom() {
            this.isInEditMode = true;
            this.editTableState(2);

            this.proceedToSituation();
        },

        async refreshPatientSymptoms() {

            await this.loadPatientSymptoms();
        },

        cancelMessageBoxShow(modal) {
            this.$refs[modal].hide();
        },
        async saveResidentData() {
            this.isSaving = true
            await this.saveEpisode().finally(() => { this.isSaving = false});

            if (this.episodeHasErrors === true) {
                this.$refs["modal-error-saving"].show();
            } else {
                this.cancelMessageBoxShow("modal-error-saving");
                this.cancelMessageBoxShow("modal-unsaved-changes");
                // await this.loadMessageWindowAfterSave();
                await this.openMessages()
            }
        },
        showConfirmUnsavedChangesModal() {
            this.$refs["modal-unsaved-changes"].show();
        },

        goBack() {

            if (this.meSignSymptomStep === ChangeInConditionPageState.SELECT_CHANGE_IN_CONDITION) {

                this.viewTableState(ChangeInConditionPageState.SELECT_CHANGE_IN_CONDITION)
            }

            if (this.meSignSymptomStep === ChangeInConditionPageState.SYMPTOMS) {

                this.viewTableState(ChangeInConditionPageState.SELECT_CHANGE_IN_CONDITION)
            }

            if (this.meSignSymptomStep === ChangeInConditionPageState.ASSESSMENT) {

                this.showSituation()
            }
        },

        proceedToSituation() {

            this.showSituation()
        },

        proceedToAssess() {

            this.showAssessment()
        },

        saveSituation() {
            this.$refs.signAndSymptomsSituationComponent.save();
            this.$store.commit("setDoSignsAndSymptomsSave", true);
            this.$emit("did-sign-and-symptom-save", true);
            this.refreshPatientSymptoms();
        },

        addAssessment() {
            this.configureAssessment()
            this.addSignsAndSymptom()
            this.clearAssessment()
            this.showTable()
        },

        doAssessAndCommunicate() {

            this.configureAssessment()
            this.addSignsAndSymptom()

            //  if the sign and symptom has a linked intervention, we need to go to that intervention
            if (this.hasIntervention) {

                //  we need to set the button to Open Intervention
                this.processIntervention()
                return
            }

            //  else we need to check to see if it requires a notification
            if (this.shouldNotify()) {

                //      if it does, we need to save
                this.processNotification()
                return

            }

            //  clear the current assessment
            this.clearAssessment()
            this.showTable()
        },

        processNotification() {

            this.showConfirmUnsavedChangesModal()
            this.clearAssessment()
        },

        processIntervention() {
            this.setIntervention()
            this.clearAssessment()
            this.$parent.doShowInterventions()
        },

        findIntervention(interventionName) {

            return this.interventions.find(intervention => intervention.intervention === interventionName)
        },

        async setIntervention() {

            //  look up the intervention
            const intervention = this.findIntervention(this.selectedSignAndSymptomObject.linked_intervention)

            //  set the page to view
            const page = 2

            //  create the intervention object
            const episodeIntervention = {
                episode_id: this.episode.id,
                intervention_id: intervention.id,
                selected_symptoms: [],
                selected_notifications: [],
                selected_tests: [],
                notification: 'Active',
                next_step: 'Assess',
                page: page,
                alert: null,
                status: 'Active',
                active: true,
                notified: false,
                notified_at: null,
                created_by: this.user.id,
                intervention: intervention.intervention,
                symptoms: intervention.symptoms,
                notifications: intervention.notifications,
                monitors: intervention.monitors,
                tests: intervention.tests,
                action: 'C',
                shouldSetAsCurrent: true,
                invokedFrom: "SignsAndSymptoms",
                episodeInterventionId: generateId()
            }

            await this.addOrUpdateIntervention(episodeIntervention);
            await this.setInterventionName(episodeIntervention);
            await this.setEpisodeInterventionId(episodeIntervention.episodeInterventionId)
            // this.changePage(page);
            // this.addOrUpdateIntervention({intervention: this.selectedSignAndSymptomObject.linked_intervention, shouldSetAsCurrent: true})
        },

        shouldNotify() {

            return this.selectedSignAndSymptomObject.linked_intervention === ''
                && this.assessment.immediacy === 'true'
                && this.assessment.has_notified === false
        },

        configureAssessment() {
            const assessment = {...this.assessment}

            assessment.action = this.determineAction(assessment)

            assessment.requires_notification = assessment.immediacy === 'true'

            assessment.is_immediate = assessment.immediacy === 'true'

            assessment.signs_and_symptom_id = this.getCurrentSignsAndSymptom

            assessment.status = 1

            if (assessment.episode_id === null) {

                assessment.episode_id = this.episode.id
            }

            if(this.hasIntervention) {
                assessment.linked_intervention = this.selectedSignAndSymptomObject.linked_intervention
            }

            this.setAssessment(assessment)
        },

        determineAction(assessment) {
            if (assessment.action === null) {

                return 'C'
            }

            if (assessment.action === 'R') {

                return 'U'
            }

            return assessment.action
        },

        async loadPatientSymptoms() {
            if (this.getEpisodeSignsAndSymptoms) {

                this.items = this.getEpisodeSignsAndSymptoms.map((episodeSymptoms) => {

                    let symptom = this.signsAndSymptoms.find(s => s.id === episodeSymptoms.signs_and_symptom_id)

                    if(!symptom) {
                        return
                    }

                    return {
                        id: episodeSymptoms.id,
                        symptom: symptom.symptom,
                        startDate: episodeSymptoms.started_at,
                        situation: episodeSymptoms.immediacy === 'true' ? symptom.immediate_text : symptom.non_immediate_text,
                        condition: (episodeSymptoms.is_reoccurrence === 'true' ? "This condition, symptom or sign has occurred before.": "This condition, symptom or sign has not occurred before.") + ' ' + episodeSymptoms.other_factors,
                        intervention: symptom.linked_intervention
                    }
                })
            }
        },

        editRecord(paRecord) {
            let doEdit = true;
            if (
                this.selectedRow &&
                !confirm("You have unsaved changes, are you sure you want to continue?")
            ) {
                doEdit = false;
            }

            if (doEdit) {
                this.isEditShow = true;
                this.selectedRow = {...paRecord};
            }
        },

        removeListItem() {
            const index = this.items.indexOf(this.selectedRow);
            if (index > -1) {
                this.items.splice(index, 1);
            }

            this.resetEdit();
            this.$bvModal.hide("confirmRemoveModal");
        },

        saveEdit() {
            let user = this.items.find((u) => u.id === this.selectedRow.id);
            Object.assign(user, this.selectedRow);

            this.resetEdit();
        },

        resetEdit() {
            this.selectedRow = null;
            this.isEditShow = false;
        },

        addRecord() {
            this.items.push({
                name: this.iFullNameAdd,
                speciality: this.iSpecialityAdd,
                email: this.iEmailAdd,
                number: this.iNumberAdd,
            });
            this.viewTableState(1);
            this.clearAddedValues();
        },

        removeAddRecord() {
            this.viewTableState(1);
            this.clearAddedValues();
        },

        clearAddedValues() {
            this.iFullNameAdd = "";
            this.iSpecialityAdd = "";
            this.iEmailAdd = "";
            this.iNumberAdd = "";
        },

        addToTableState(paNumberState) {
            if (this && this.isInAddMode === false) {
                this.isInAddMode = true;
                this.editedPatientSymptom = "";
                this.resetEpisodeSignsAndSymptom()
                this.clearAssessment()
                // this.allocateSelectedSymptom()
                this.setNewSignAndSymptom(null)
                this.activeTab = paNumberState;
            }

        },

        editTableState(tableState) {
            if(this && this.isInAddMode === false) {

                this.isInAddMode = true;
                this.editedPatientSymptom = ""
                this.resetEpisodeSignsAndSymptom()
                this.activeTab = tableState
            }
        },

        showTable() {
            this.showSituationWindow = false
            this.showAssessWindow = false
            this.meSignSymptomStep = 1
            this.isInAddMode = false;
            this.isInEditMode = false;
            this.editedPatientSymptom = "";
            this.activeTab = 1;
            this.loadPatientSymptoms();
        },

        showSituation() {

            this.showSituationWindow = true
            this.showAssessWindow = false
            this.meSignSymptomStep = 2
        },

        showAssessment() {

            this.showSituationWindow = false
            this.showAssessWindow = true
            this.meSignSymptomStep = 3
        },

        resetEpisodeSignsAndSymptom() {
            this.episodeSignsAndSymptom = {
                episode_id: null,
                signs_and_symptom_id: null,
                is_immediate: false,
                started_at: null,
                is_reoccurrence: false,
                severity_direction: 'same',
                last_treatment: null,
                positive_factors: null,
                negative_factors: null,
                other_factors: null,
                requires_notification: false,
                has_notified: false,
                status: true,
                action: 'R'
            }
        },

        viewTableState(paNumberState) {
            this.isInAddMode = false;
            this.isInEditMode = false;
            this.editedPatientSymptom = "";
            this.activeTab = paNumberState;
            if (paNumberState === 1) {
                this.loadPatientSymptoms();
            }
        },
    },

    watch: {
        meSignSymptomStep: function (newValue) {
            this.$store.commit("setSignSymptomStep", newValue);
        },
    },
};
</script>


